import { EMapToolActionType } from '../actions/MapToolActionType';

const initialState = {
  // State related to editing variables.
  variableEditTargetId: null,
  variableEditLoading: false,
  variableEditLoadFailed: false,
};

/**
 * Variable Map Tool Reducer.
 * - returns new states for matched Variable Map tool message actions. They are typically related to drawing, editing, selections, etc.
 *
 * @name GeometryMapToolReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EMapToolActionType.VARIABLE_EDIT:
      return {
        ...state,
        variableEditTargetId: action.variableEditTargetId,
      };

    case EMapToolActionType.VARIABLE_EDIT_LOAD:
      return {
        ...state,
        variableEditLoading: true,
        variableEditLoadFailed: false,
      };

    case EMapToolActionType.VARIABLE_EDIT_LOAD_FAILED:
      return {
        ...state,
        variableEditLoading: false,
        variableEditLoadFailed: true,
      };

    case EMapToolActionType.VARIABLE_EDIT_LOAD_SUCCESS:
      return {
        ...state,
        variableEditLoading: false,
        variableEditLoadFailed: false,
      };

    case EMapToolActionType.VARIABLES_UNLOAD: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
}
