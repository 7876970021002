import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceMeshActionType } from '../actions/WorkspaceMeshActionType';
import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { Fill, Style, Circle as CircleStyle, Stroke } from 'ol/style';
import { MIKE_COLORS, MIKE_MAP_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { FeatureCollection, Point, Feature, Polygon } from 'geojson';
import { EMapToolActionType } from '../actions/MapToolActionType';

export interface INodeNeighbours {
  nodeIndex: number;
  nodeX: number;
  nodeY: number;
  neighbouringFeatures: Array<Feature<Polygon>>;
}

export interface IChangedNode {
  nodeIndex: number;
  nodeX: number;
  nodeY: number;
}

const {
  setDrawVectorLayerGeojson,
  update2DData,
  enable2DPointDrawing,
  disable2DPointDrawing,
  getConfiguration,
  clearDrawnVectorLayerData,
  delete2DData,
} = MikeVisualizer;

const { ol } = getConfiguration();

const nodeEditPointNoStyle = new Style({});
const nodeEditPointStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: MIKE_COLORS.WHITE,
    }),
    stroke: new Stroke({
      color: MIKE_MAP_COLORS.VIOLET,
      width: ol.strokeWidth / 2,
    }),
    radius: ol.pointRadius / 2,
  }),
});

const NEIGHBOUR_DATA_ID = 'neighbour-data';
const DEFAULT_NEIGHBOUR_OPACITY = 0.8;
const DEFAULT_NEIGHBOUR_BACKGROUND_COLOR = `rgba(158,181,194, ${DEFAULT_NEIGHBOUR_OPACITY})`;

/**
 * Defines wich mesh tools are allowed by default and which are not.
 */
const defaultAllowedTools = {
  meshSpatialSelectionAllowed: false,
};

const initialState = {
  ...defaultAllowedTools,
  mapToolsSupportedByProjection: true, // Map projection is assumed to support map tools by default

  // The target of the mesh spatial selection.
  meshSpatialSelectionTargetId: null,

  // State related to editing mesh nodes
  meshEditNodesTargetId: null,
  selectedX: null,
  selectedY: null,
  nodeNeighbours: null,
  meshNodeLoading: false,
  meshNodeLoadingFailed: false,
  nodesToUpdate: new Array<IChangedNode>(),
  meshNodeSaveInProgress: false,
  saveNodeFailed: false,
  newNodeX: null,
  newNodeY: null,
  colorFromMeshSurface: null,
  newNeighbouringFeatures: new Array<Feature<Polygon>>(),
};

export type MeshMapToolState = {
  meshSpatialSelectionAllowed: boolean;
  mapToolsSupportedByProjection: boolean;
  meshSpatialSelectionTargetId: string | null;
  meshEditNodesTargetId: string | null;
  selectedX: number | null;
  selectedY: number | null;
  nodeNeighbours: INodeNeighbours | null;
  meshNodeLoading: boolean;
  meshNodeLoadingFailed: boolean;
  nodesToUpdate: Array<IChangedNode>;
  meshNodeSaveInProgress: boolean;
  saveNodeFailed: boolean;
  newNodeX: number | null;
  newNodeY: number | null;
  colorFromMeshSurface: string | null;
  newNeighbouringFeatures: Array<Feature<Polygon>>;
};

/**
 * Returns a full state object with all the tools not supported by projection being disallowed.
 * Eventually this will hide the tools completely in the toolbar.
 *
 * @param state - GeometryToolState
 */
const disallowNotSupportedToolsOnState = (state: MeshMapToolState): MeshMapToolState => {
  console.debug('NB: Mesh Map tools not supported.');
  return {
    ...state,
    // ools relying on projection
    meshSpatialSelectionAllowed: false,
  };
};

/**
 * Mesh Map Tool Reducer.
 * - returns new states for matched Mesh Map tool message actions. They are typically related to drawing, selections, editing, etc.
 *
 * @name MeshMapToolReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case EMapToolActionType.RESET_ALLOWED_TOOLS: {
      const nextState = {
        ...state,
        ...defaultAllowedTools,
      };

      // disallow those not supported by projection
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(nextState);
      }

      return nextState;
    }

    case 'maptools/ALLOW/MAPTOOLS': {
      return {
        ...state,
        mapToolsSupportedByProjection: true,
      };
    }

    case EMapToolActionType.DISALLOW_MAPTOOLS: {
      return {
        ...disallowNotSupportedToolsOnState(state),
        mapToolsSupportedByProjection: false,
      };
    }

    case 'maptools/mesh/ALLOW/SPATIAL_SELECTION':
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }

      return {
        ...state,
        meshSpatialSelectionAllowed: state.mapToolsSupportedByProjection,
      };

    case 'maptools/mesh/SET_SPATIAL_SELECTION_TARGET':
      return {
        ...state,
        meshSpatialSelectionTargetId: action.meshSpatialSelectionTargetId,
      };

    case EWorkspaceMeshActionType.EDIT_NODES:
      return {
        ...state,
        meshEditNodesTargetId: action.data,
      };

    case EWorkspaceMeshActionType.GET_NODE_NEIGHBOURS: {
      disable2DPointDrawing();
      return {
        ...state,
        meshNodeLoading: true,
        meshNodeLoadingFailed: false,
        selectedX: action.selectedX,
        selectedY: action.selectedY,
      };
    }

    case EWorkspaceMeshActionType.LOADING_NODE_NEIGHBOURS: {
      return { ...state, meshNodeLoading: action.meshNodeLoading };
    }

    case EWorkspaceMeshActionType.GET_NODE_NEIGHBOURS_FAILED: {
      const { meshNodeLoadingFailed, selectedX, selectedY } = action;
      return { ...state, meshNodeLoadingFailed, selectedX, selectedY };
    }

    case EWorkspaceMeshActionType.SET_NODE_NEIGHBOURS: {
      const nodeNeighbours = action.data.nodeNeighbours;
      const selectedX = action.data.selectedX;
      const selectedY = action.data.selectedY;

      if (nodeNeighbours && nodeNeighbours.nodeX && nodeNeighbours.nodeY && nodeNeighbours.nodeIndex >= 0) {
        const features = nodeNeighbours.neighbouringFeatures;
        const enrichedFeatures =
          features && features.length > 0
            ? features.map((feature: Feature<Polygon, any>) => {
                return {
                  ...feature,
                  properties: feature.properties
                    ? { ...feature.properties, nodeIndex: nodeNeighbours.nodeIndex }
                    : { nodeIndex: nodeNeighbours.nodeIndex },
                };
              })
            : features;

        update2DData(
          {
            type: 'FeatureCollection',
            features: enrichedFeatures,
          },
          NEIGHBOUR_DATA_ID,
          state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
          MIKE_MAP_COLORS.VIOLET,
        );
        // Enable point drawing when a node is selected (node index becomes available).
        enable2DPointDrawing(true, true, nodeEditPointStyle, nodeEditPointNoStyle, nodeEditPointStyle);
        // Point geojson is created & appended as drawn data so it can be edited.
        const featureCollection = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              id: nodeNeighbours.nodeIndex,
              geometry: {
                type: 'Point',
                coordinates: [nodeNeighbours.nodeX, nodeNeighbours.nodeY],
              },
            },
          ],
        } as FeatureCollection<Point, any>;

        setDrawVectorLayerGeojson(featureCollection);
        return {
          ...state,
          meshNodeLoading: false,
          meshNodeLoadingFailed: false,
          nodeNeighbours: { ...nodeNeighbours, neighbouringFeatures: enrichedFeatures },
          selectedY,
        };
      } else {
        disable2DPointDrawing();
      }

      return { ...state, meshNodeLoading: false, meshNodeLoadingFailed: false, nodeNeighbours, selectedX, selectedY };
    }

    case EMapToolActionType.CLEAR_SELECTION_FOR_EDITING: {
      disable2DPointDrawing();
      clearDrawnVectorLayerData();
      if (state.nodeNeighbours) {
        const newNeighbouringFeatures = state.newNeighbouringFeatures.filter(
          (feature: Feature<Polygon, any>) =>
            feature.properties && feature.properties.nodeIndex !== state.nodeNeighbours.nodeIndex,
        );
        const newNeighbouringFeatureCollection = {
          type: 'FeatureCollection',
          features: newNeighbouringFeatures,
        } as FeatureCollection<any, any>;
        update2DData(
          newNeighbouringFeatureCollection,
          NEIGHBOUR_DATA_ID,
          state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
          MIKE_MAP_COLORS.VIOLET,
        );
        return {
          ...state,
          selectedX: null,
          selectedY: null,
          newNodeX: null,
          newNodeY: null,
          nodeNeighbours: null,
          newNeighbouringFeatures,
        };
      }

      return {
        ...state,
        selectedX: null,
        selectedY: null,
        newNodeX: null,
        newNodeY: null,
        nodeNeighbours: null,
      };
    }

    case EWorkspaceMeshActionType.SUBMIT_NODE_UPDATE: {
      disable2DPointDrawing();
      const newX = state.newNodeX;
      const newY = state.newNodeY;
      if (state.nodeNeighbours && state.nodeNeighbours.nodeIndex >= 0 && newX && newY) {
        const nodesToUpdate = [
          ...state.nodesToUpdate,
          { nodeIndex: state.nodeNeighbours.nodeIndex, nodeX: newX, nodeY: newY },
        ];
        return {
          ...state,
          selectedX: null,
          selectedY: null,
          newNodeX: null,
          newNodeY: null,
          nodeNeighbours: null,
          nodesToUpdate,
        };
      }
      return state;
    }

    case EWorkspaceMeshActionType.UNDO_NODE_SUBMIT: {
      const nodeIndex = action.data;
      disable2DPointDrawing();
      clearDrawnVectorLayerData();
      const newNeighbouringFeatures = state.newNeighbouringFeatures.filter(
        (feature: Feature<Polygon, any>) => feature.properties && feature.properties.nodeIndex !== nodeIndex,
      );
      const newNeighbouringFeatureCollection = {
        type: 'FeatureCollection',
        features: newNeighbouringFeatures,
      } as FeatureCollection<any, any>;
      update2DData(
        newNeighbouringFeatureCollection,
        NEIGHBOUR_DATA_ID,
        state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
        MIKE_MAP_COLORS.VIOLET,
      );
      return {
        ...state,
        selectedX: null,
        selectedY: null,
        newNodeX: null,
        newNodeY: null,
        nodeNeighbours: null,
        newNeighbouringFeatures,
      };
    }

    case EWorkspaceMeshActionType.FINISH_NODE_UPDATES: {
      disable2DPointDrawing();
      clearDrawnVectorLayerData();
      delete2DData(NEIGHBOUR_DATA_ID);
      return {
        ...state,
        meshEditNodesTargetId: null,
        selectedX: null,
        selectedY: null,
        nodeNeighbours: null,
        meshNodeLoading: false,
        meshNodeLoadingFailed: false,
        nodesToUpdate: new Array<IChangedNode>(),
        newNeighbouringFeatures: new Array<Feature<Polygon>>(),
        newNodeX: null,
        newNodeY: null,
      };
    }

    case EWorkspaceMeshActionType.SAVE_NODE_UPDATES: {
      return { ...state, meshNodeSaveInProgress: true, saveNodeFailed: false };
    }

    case EWorkspaceMeshActionType.SAVE_NODE_UPDATES_FAILED: {
      return { ...state, meshNodeSaveInProgress: false, saveNodeFailed: true };
    }

    case EWorkspaceMeshActionType.SAVE_NODE_UPDATES_SUCCEEDED: {
      return { ...state, meshNodeSaveInProgress: false, saveNodeFailed: false };
    }

    case EWorkspaceMeshActionType.SET_NEW_NODE_COORDS: {
      const { id, newCoordinates } = action;

      if (newCoordinates && newCoordinates.length > 1) {
        const [newX, newY] = newCoordinates;
        // If updates get triggered with the same position, do nothing.
        if (newX === state.newNodeX && newY === state.newNodeY) {
          return state;
        }
      } else {
        return state;
      }

      const nodeNeighbours: INodeNeighbours | null = state.nodeNeighbours;

      const inside = (vs, newX, newY) => {
        const n = vs.length;
        let is_in = false;
        let x1;
        let x2;
        let y1;
        let y2;

        const x = newX;
        const y = newY;

        for (let i = 0; i < n - 1; ++i) {
          x1 = vs[i][0];
          x2 = vs[i + 1][0];
          y1 = vs[i][1];
          y2 = vs[i + 1][1];

          // disable eslint for mixed operator rules in line 212
          /*eslint-disable */
          if (y < y1 !== y < y2 && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1) {
            is_in = !is_in;
          }
          /*eslint-enable */
        }

        return is_in;
      };

      if (nodeNeighbours && nodeNeighbours.neighbouringFeatures && nodeNeighbours.nodeX && nodeNeighbours.nodeY) {
        const nodeNeighbourFeatures = nodeNeighbours.neighbouringFeatures;

        const [newX, newY] = newCoordinates;
        const isInside = [];
        nodeNeighbourFeatures.forEach((neig) => {
          const neightCoords = neig.geometry.coordinates[0];
          const check = inside(neightCoords, newX, newY);
          if (check) {
            isInside.push('isInside');
          }
        });

        if (!isInside.includes('isInside')) {
          return state;
        }
        // Update neighbouring features, by updating the coordinates of the selected node.
        const newFeatures = nodeNeighbourFeatures.map((feature) => {
          const coordinates = feature.geometry.coordinates[0];

          const updatedCoordinates = coordinates.map((coordinate) => {
            const [x, y, z] = coordinate;
            if (x === nodeNeighbours.nodeX && y === nodeNeighbours.nodeY) {
              return [newX, newY, z];
            }

            return [x, y, z];
          });

          return {
            ...feature,
            properties: feature.properties ? { ...feature.properties, nodeIndex: id } : { nodeIndex: id },
            geometry: {
              ...feature.geometry,
              coordinates: [updatedCoordinates],
            },
          };
        });
        const newNeighbouringFeatures = state.newNeighbouringFeatures
          .filter((feature: Feature<Polygon, any>) => feature.properties && feature.properties.nodeIndex !== id)
          .concat(newFeatures);
        const newNeighbouringFeatureCollection = {
          type: 'FeatureCollection',
          features: newNeighbouringFeatures,
        } as FeatureCollection<any, any>;
        update2DData(
          newNeighbouringFeatureCollection,
          NEIGHBOUR_DATA_ID,
          state.colorFromMeshSurface || DEFAULT_NEIGHBOUR_BACKGROUND_COLOR,
          MIKE_MAP_COLORS.VIOLET,
        );
        return { ...state, newNodeX: newX, newNodeY: newY, newNeighbouringFeatures };
      }

      return state;
    }
    case EWorkspaceMeshActionType.SET_COLOR_FROM_MESH_SURFACE: {
      const colorFromMeshSurface = action.colorFromMeshSurface;
      return { ...state, colorFromMeshSurface };
    }

    default:
      return state;
  }
}
