import { takeLatest, put, call, takeEvery, select } from 'redux-saga/effects';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';
import * as MeshMoveNodeOperationsProxy from 'proxies/MeshMoveNodeOperationsProxy';
import { IGlobalState } from 'src/store';
import { getRouteParams } from 'src/workspaces/sagas/selectors';
import { EWorkspaceActionType } from 'src/store/actions/WorkspaceActionType';
import { t } from 'src/translations/i18n';
import { IChangedNode } from 'src/store/reducers/MeshMapToolReducer';
import { EMapToolActionType } from 'src/store/actions/MapToolActionType';
import { ESelectForEditingModeIds } from 'src/shared/edit-modes/select-for-editing-modes';

const getMeshId = (state: IGlobalState) => state.MeshMapToolReducer.meshEditNodesTargetId;
const getNodesToUpdate = (state: IGlobalState) => state.MeshMapToolReducer.nodesToUpdate;
const getSelectedX = (state: IGlobalState) => state.MeshMapToolReducer.selectedX;
const getSelectedY = (state: IGlobalState) => state.MeshMapToolReducer.selectedY;
const getNewX = (state: IGlobalState) => state.MeshMapToolReducer.newNodeX;
const getNewY = (state: IGlobalState) => state.MeshMapToolReducer.newNodeY;
const getNodeNeighbours = (state: IGlobalState) => state.MeshMapToolReducer.nodeNeighbours;

/**
 * Defines sagas for loading which items are exportable
 */
export default function* watchEditMeshes() {
  yield takeLatest(EWorkspaceMeshActionType.GET_NODE_NEIGHBOURS, handleGetNodeNeighbours);
  yield takeEvery(EWorkspaceMeshActionType.SAVE_NODE_UPDATES, handleSaveNodeUpdates);
}

/**
 * Generator function for editing meshes
 * @param action
 */
function* handleGetNodeNeighbours(action) {
  const { x, y, retry } = action;
  const { workspaceId } = yield select(getRouteParams);
  const meshId = yield select(getMeshId);
  const selectedX = yield select(getSelectedX);
  const selectedY = yield select(getSelectedY);
  const previousNodesToUpdate: Array<IChangedNode> = yield select(getNodesToUpdate);

  if (retry || (!selectedX && !selectedY)) {
    try {
      const response = yield call(
        MeshMoveNodeOperationsProxy.findNearestAt,
        workspaceId,
        meshId,
        x,
        y,
        previousNodesToUpdate,
      );
      const nodeNeighbours = response.data;
      yield put({ type: EWorkspaceMeshActionType.SET_NODE_NEIGHBOURS, data: { nodeNeighbours, selectedX, selectedY } });
    } catch (error) {
      yield put({
        type: EWorkspaceMeshActionType.GET_NODE_NEIGHBOURS_FAILED,
        meshNodeLoadingFailed: false,
        selectedX,
        selectedY,
      });
    }
  }
}

function* handleSaveNodeUpdates() {
  const changedNodes: Array<IChangedNode> = yield select(getNodesToUpdate);
  let previousNodesToUpdate: { [key: number]: [number, number] };
  changedNodes.forEach((changedNode: IChangedNode) => {
    previousNodesToUpdate = {
      ...previousNodesToUpdate,
      [changedNode.nodeIndex]: [changedNode.nodeX, changedNode.nodeY],
    };
  });
  const newX = yield select(getNewX);
  const newY = yield select(getNewY);
  const nodeNeighbours = yield select(getNodeNeighbours);
  const moreNodesToUpdate = nodeNeighbours && nodeNeighbours.nodeIndex >= 0 && newX && newY ? true : false;
  const nodesToUpdate: { [key: number]: [number, number] } = moreNodesToUpdate
    ? { ...previousNodesToUpdate, [nodeNeighbours.nodeIndex]: [newX, newY] }
    : previousNodesToUpdate;
  const { workspaceId } = yield select(getRouteParams);
  const meshId = yield select(getMeshId);
  try {
    yield call(MeshMoveNodeOperationsProxy.moveNodes, workspaceId, meshId, nodesToUpdate);
    yield put({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES_SUCCEEDED });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
    yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('MESH_EDIT_NODES_SUBMITTED') } });
  } catch (error) {
    yield put({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES_FAILED });
  } finally {
    yield put({
      type: EMapToolActionType.SET_SELECTION_MODE,
      data: ESelectForEditingModeIds.SELECT_BY_POINT,
    });
  }
}
