import { takeLatest, put, call, takeEvery, select, delay } from 'redux-saga/effects';
import * as WorkspaceProxy from 'proxies/WorkspaceProxy';
import * as ProjectProxy from 'proxies/ProjectProxy';
import * as ProjectionProxy from 'src/proxies/ProjectionProxy';
import * as WorkspaceVariablesProxy from 'src/proxies/WorkspaceVariablesProxy';
import * as WorkspaceGeometriesProxy from 'src/proxies/WorkspaceGeometriesProxy';
import * as WorkspaceQueryProxy from 'src/proxies/WorkspaceQueryProxy';
import { IAction } from 'src/store/actions/Action';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { t } from 'src/translations/i18n';
import { IResponse } from 'src/models/IResponse';
import { IWorkspace, IWorkspaceOverview } from 'src/models/IWorkspaces';
import { IGlobalState } from 'src/store';
import { history } from 'src';
import { getRouteByPath, ROUTES } from 'src/app/routes';
import * as ProjectContentProxy from 'proxies/ProjectContentProxy';
import { mapDatasets } from 'src/managers/WorkspaceManager';
import { IProjection } from 'src/models/IProjections';
import { EMapToolActionType } from 'src/store/actions/MapToolActionType';
import { FeatureCollection } from 'geojson';
import { EItemType } from 'src/models/IOperationDescriptions';
import {
  clearFeaturesForEditing,
  createGeoemtryFailed,
  createVariableFailed,
  creatingGeometry,
  creatingVariable,
  setLoadingFeaturesForEditing,
  updateGeometryDataFailed,
  updateVariableDataFailed,
  updatingGeometryData,
  updatingVariableData,
} from 'src/store/actions/editingItems';
import { HttpErrorHandler } from 'src/managers/http-utils';
import { EWorkspaceVariableActionType } from 'src/store/actions/WorkspaceVariableActionType';
import { EWorkspaceActionType } from 'src/store/actions/WorkspaceActionType';
import { USAGE_TYPES } from 'src/project-datasets/mesh-project-dataset-constants';
import { ISelectionQueryApi, QUERY_RELATIONS } from 'src/models/IQueryDefinitions';
import { IOperationMetadata } from 'src/models/IOperations';
import { EWorkspaceGeometryActionType } from 'src/store/actions/WorkspaceGeometryActionType';
import { EGeometryItemTypes } from 'src/models/IGeometries';

const getBreadcrumps = (state: IGlobalState) => state.AppReducer.breadcrumbs;

/**
 * Defines sagas for workspace CRUD operations
 */
export default function* watchWorkspaceEdit() {
  yield takeLatest(WorkspaceActionType.WORKSPACE_LOAD_OVERVIEW, loadWorkspaceOverview);
  yield takeLatest(WorkspaceActionType.WORKSPACE_LOAD_METADATA, loadWorkspaceMetadata);
  yield takeLatest(WorkspaceActionType.LOAD_WORKSPACE, loadWorkspace);
  yield takeEvery(WorkspaceActionType.UPDATE_WORKSPACE, updateWorkspace);
  yield takeLatest(WorkspaceActionType.WORKSPACE_ADD_PROJ4STRING, enrichWorkspaceWithProj4String);
  yield takeLatest(EMapToolActionType.GET_FEATURES_FOR_EDITING, handleGetFeaturesForEditing);
  yield takeEvery(EWorkspaceVariableActionType.UPDATE_DATA, handleUpdateVariableData);
  yield takeEvery(EWorkspaceVariableActionType.CREATE, handleCreateVariable);
  yield takeEvery(EWorkspaceGeometryActionType.UPDATE, handleUpdateGeometryData);
  yield takeEvery(EWorkspaceGeometryActionType.CREATE, handleCreateGeometry);
}

interface IGetFeaturesForEditing {
  workspaceId: string;
  itemId: string;
  itemType: EItemType;
  selectionFeatureCollection: FeatureCollection<any, any>;
  previousEdits: Array<FeatureCollection<any, any>>;
  geometryEditType: EGeometryItemTypes;
}

interface IPostEdits {
  workspaceId: string;
  variableId?: string;
  geometryId?: string;
  meshId?: string;
  previousEdits: Array<FeatureCollection<any, any>>;
}

function* handleCreateVariable(action: IAction<IPostEdits>) {
  const { workspaceId, previousEdits } = action.data;
  yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('VARIABLE_DRAWING_SUBMITTED_SUCCESSFULLY') } });
  yield put(creatingVariable());
  try {
    yield call(
      WorkspaceVariablesProxy.createVariableDataWithCachedEdits,
      workspaceId,
      t('SCATTER'),
      USAGE_TYPES.VARIABLE,
      previousEdits,
      {},
    );
    yield put(clearFeaturesForEditing());
    yield put({ type: EMapToolActionType.VARIABLES_UNLOAD });
    yield put({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  } catch (error) {
    yield put(createVariableFailed());
    yield put({ type: 'toast/ADD/ERROR', toast: { text: t('VARIABLE_DRAWING_SUBMIT_FAILED') } });
    HttpErrorHandler('Failed to update variable data with geojson.', error);
  } finally {
    yield put(creatingVariable(false));
  }
}

function* handleUpdateVariableData(action: IAction<IPostEdits>) {
  const { workspaceId, variableId, previousEdits } = action.data;
  yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('VARIABLE_EDIT_UPDATE_SUBMITTED') } });
  yield put(updatingVariableData());
  try {
    yield call(WorkspaceVariablesProxy.updateVariableDataWithcachedEdits, workspaceId, variableId, previousEdits);
    yield put(clearFeaturesForEditing());
    yield put({ type: EMapToolActionType.VARIABLES_UNLOAD });
    yield put({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  } catch (error) {
    yield put(updateVariableDataFailed());
    yield put({ type: 'toast/ADD/ERROR', toast: { text: t('VARIABLE_EDIT_UPDATE_FAILED') } });
    HttpErrorHandler('Failed to update variable data with geojson.', error);
  } finally {
    yield put(updatingVariableData(false));
  }
}

function* handleCreateGeometry(action: IAction<IPostEdits>) {
  const { workspaceId, previousEdits } = action.data;
  yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('GEOMETRY_DRAWING_SUBMITTED_SUCCESSFULLY') } });
  yield put(creatingGeometry());
  try {
    yield call(WorkspaceGeometriesProxy.createGeometryDataWithCachedEdits, workspaceId, t('GEOMETRY'), previousEdits);
    yield put(clearFeaturesForEditing());
    yield put({ type: EMapToolActionType.VARIABLES_UNLOAD });
    yield put({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  } catch (error) {
    yield put(createGeoemtryFailed());
    yield put({ type: 'toast/ADD/ERROR', toast: { text: t('GEOMETRY_DRAWING_SUBMIT_FAILED') } });
    HttpErrorHandler('Failed to update geometry data with geojson.', error);
  } finally {
    yield put(creatingGeometry(false));
  }
}

function* handleUpdateGeometryData(action: IAction<IPostEdits>) {
  const { workspaceId, geometryId, previousEdits } = action.data;
  yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('GEOMETRY_EDIT_UPDATE_SUBMITTED') } });
  yield put(updatingGeometryData());
  try {
    yield call(WorkspaceGeometriesProxy.updateGeometryDataWithcachedEdits, workspaceId, geometryId, previousEdits);
    yield put(clearFeaturesForEditing());
    yield put({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  } catch (error) {
    yield put(updateGeometryDataFailed());
    yield put({ type: 'toast/ADD/ERROR', toast: { text: t('GEOMETRY_EDIT_UPDATE_FAILED') } });
    HttpErrorHandler('Failed to update geometry data with geojson.', error);
  } finally {
    yield put(updatingGeometryData(false));
  }
}

function* handleGetFeaturesForEditing(action: IAction<IGetFeaturesForEditing>) {
  const { workspaceId, itemId, itemType, selectionFeatureCollection, previousEdits, geometryEditType } = action.data;
  yield put(setLoadingFeaturesForEditing());
  switch (itemType) {
    case EItemType.VARIABLE: {
      yield put({ type: EMapToolActionType.VARIABLE_EDIT_LOAD });
    }
  }

  const relationType =
    geometryEditType === EGeometryItemTypes.POINT ? QUERY_RELATIONS.ISWITHIN : QUERY_RELATIONS.INTERSECTS;

  try {
    const payload: ISelectionQueryApi = {
      type: 'SpatialQueryDefinition2',
      relation: relationType, // relation of target geometries to selection geometry
      featureCollection: selectionFeatureCollection,
      targetItems: [itemId],
      resultReturnType: 'featureCollection',
      resultType: 'geometry',
      intermediateFeatureCollections: previousEdits,
    };

    const response: { data: IOperationMetadata } = yield call(
      WorkspaceQueryProxy.createSelectionQuery,
      workspaceId,
      payload,
    );

    if (response.data && response.data.id) {
      yield put({
        type: EMapToolActionType.SET_FEATURES_FOR_EDITING_IDS,
        data: { operationId: response.data.id, targetId: itemId },
      });
    }
  } catch (error) {
    yield put({ type: EMapToolActionType.LOADING_FEATURES_FOR_EDITING_FAILED });
  }
}

function* loadWorkspaceOverview(action: IAction<string>) {
  const projectId = action.data;
  let project = null;
  if (projectId) {
    yield put({ type: 'workspaces/LIST' });
    try {
      const response: IResponse = yield call(ProjectContentProxy.getProject, projectId);
      if ((response.status === 200 || response.status === 201) && response.data) {
        project = response.data;
        yield put({ type: 'project/LOAD_SUCCESS', project });
      }
    } catch (error) {
      yield call(history.push, ROUTES.notFound.path);
    }
    if (project) {
      try {
        const response: IResponse = yield call(WorkspaceProxy.getWorkspaceOverviewList, projectId, true);
        if ((response.status === 200 || response.status === 201) && response.data) {
          const workspaces: Array<IWorkspaceOverview> = response.data;
          yield put({
            type: 'workspaces/LIST_SUCCESS',
            workspaces,
          });
        }
        const subProjectsResponse = yield call(ProjectProxy.getSubprojectsList, projectId);
        if ((subProjectsResponse.status === 200 || subProjectsResponse.status === 201) && subProjectsResponse.data) {
          const subprojects = subProjectsResponse.data;
          yield put({
            type: 'project/SUBPROJECT_LIST_SUCCES',
            subprojects,
          });
        }
      } catch (error) {
        yield call(history.push, ROUTES.notFound.path);
      }
    }
  }
}

function* enrichWorkspaceWithProj4String(action) {
  const { workspace } = action;
  let enrichedWorkspace = workspace;
  if (workspace.epsgCode) {
    const projectionResponse = yield call(ProjectionProxy.getProjectionByEpsg, workspace.epsgCode);
    if (projectionResponse && projectionResponse.data) {
      const projection: IProjection = projectionResponse.data;
      if (projection && projection.proj4String) {
        enrichedWorkspace = { ...workspace, proj4String: projection.proj4String };
      }
    }
  }
  yield put({
    type: WorkspaceActionType.WORKSPACE_LOAD_SUCCESS,
    workspace: enrichedWorkspace,
  });
}

function* loadWorkspaceMetadata(action: IAction<{ pId: string; wsId: string }>) {
  const projectId = action.data.pId;
  const workspaceId = action.data.wsId;
  let project = null;
  if (projectId && workspaceId) {
    yield put({ type: WorkspaceActionType.WORKSPACE_LOADING });
    try {
      const response: IResponse = yield call(ProjectContentProxy.getProject, projectId);
      if ((response.status === 200 || response.status === 201) && response.data) {
        project = response.data;
        yield put({ type: 'project/LOAD_SUCCESS', project });
      }
    } catch (error) {
      yield call(history.push, ROUTES.notFound.path);
    }

    if (project) {
      try {
        const response: IResponse = yield call(WorkspaceProxy.getWorkspaceOverview, workspaceId);
        if ((response.status === 200 || response.status === 201) && response.data) {
          const workspace: IWorkspaceOverview = response.data;
          yield put({ type: WorkspaceActionType.WORKSPACE_ADD_PROJ4STRING, workspace });
          const recentWs = { id: workspaceId, epsgCode: workspace.epsgCode, name: workspace.name };
          yield put({ type: 'workspace/recent/ADD', recentWs });
        }
      } catch (error) {
        yield call(history.push, ROUTES.notFound.path);
      }
    }
  }
}

/**
 * Generator function for loading workspace
 * @param action
 */
function* loadWorkspace(action: IAction<string>) {
  const workspaceId = action.data;
  yield put({ type: WorkspaceActionType.WORKSPACE_LOADING });
  yield put({ type: WorkspaceActionType.WORKSPACE_UPDATING, updating: true });
  try {
    const response: IResponse = yield call(WorkspaceProxy.getWorkspace, workspaceId);

    if ((response.status === 200 || response.status === 201) && response.data) {
      const workspace: IWorkspace = response.data;
      yield put({ type: WorkspaceActionType.WORKSPACE_ADD_PROJ4STRING, workspace });
    }
  } catch (error) {
    yield call(history.push, ROUTES.notFound.path);
  } finally {
    yield put({ type: WorkspaceActionType.WORKSPACE_UPDATING, updating: false });
  }
}

/**
 * Generator function for updating workspace
 * @param action
 */
function* updateWorkspace(action: IAction<{ workspace: IWorkspace; navigateToWorkspacePanel: boolean }>) {
  const breadcrumbs = yield select(getBreadcrumps);
  const { workspace, navigateToWorkspacePanel } = action.data;
  const workspaceId = workspace.id;
  const projectId = workspace.projectId;
  yield put({ type: WorkspaceActionType.WORKSPACE_UPDATING, updating: true });

  try {
    const data = mapDatasets(workspace.datasets);
    const response: IResponse = yield call(WorkspaceProxy.updateWorkspace, workspace.id, {
      ...workspace,
      datasets: data,
    });

    if ((response.status === 200 || response.status === 201 || response.status === 202) && response.data) {
      yield put({
        type: WorkspaceActionType.LOAD_WORKSPACE,
        data: workspace.id,
      });
      const toast = { text: t('UPDATED', 1, { thing: workspace.name }) };
      yield put({ type: 'toast/ADD/SUCCESS', toast });
    }

    if (navigateToWorkspacePanel) {
      // after ws update success we update the crumbs as well
      let newBreadcrumbs = [];
      newBreadcrumbs = [...breadcrumbs];
      newBreadcrumbs[newBreadcrumbs.length - 1].name = workspace.name;
      yield put({ type: 'app/breadcrumbs/SET', breadcrumbs: [...newBreadcrumbs] });

      // and navigate to workspace panel
      const workspacePanelPath = getRouteByPath(ROUTES.workspacePanel.path, {
        projectId,
        workspaceId,
      });
      yield call(history.push, workspacePanelPath);
    }
  } catch (error) {
    const toast = {
      text: t('FAILED_TO', 1, {
        action: t('LOAD', 1, { thing: t('WORKSPACE') }),
      }),
      operationId: error.operationId,
    };
    yield put({ type: 'workspace/LOAD_FAILED' });
    yield put({ type: 'toast/ADD/ERROR', toast });
  } finally {
    yield put({ type: WorkspaceActionType.WORKSPACE_UPDATING, updating: false });
  }
}
