import { isEqual, uniq, isNaN as _isNaN } from 'lodash-es';
import uuid from 'uuid';
import { IAction } from '../actions/Action';
import { EMapToolActionType } from '../actions/MapToolActionType';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { Feature, FeatureCollection, Point } from 'geojson';
import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import {
  CELLDATA,
  DEFAULT_VARIABLE_PROPERTIES,
  IGNORE_VARIABLE_PROPERTIES,
  MMG_,
  addEditValue,
  getNumericValue,
  transferElevationToZValue,
} from 'src/variables/create/variable-draw-constants';
import { isNumeric } from '@mike/mike-shared-frontend/mike-shared-helpers/helpers';
import { EEditModeIds } from 'src/shared/edit-modes/edit-modes';
import { ESelectForEditingModeIds } from 'src/shared/edit-modes/select-for-editing-modes';
import { OLDRAW_CLICKTOLERANCE } from 'src/workspaces/viewer/viewer-constants';
import { Fill, Style, Text, Circle, Stroke } from 'ol/style';
import { LABELFONTSIZE, editStyleFunction } from 'src/workspaces/viewer/viewer-utils';
import { FONTFAMILY } from '@mike/mike-shared-frontend/mike-shared-styles';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import tinycolor from 'tinycolor2';
import { getFeatureId } from 'src/variables/create/variable-draw-utils';
import { EGeometryItemTypes } from 'src/models/IGeometries';
import { EItemType } from 'src/models/IOperationDescriptions';
import { EWorkspaceMeshActionType } from '../actions/WorkspaceMeshActionType';

// This reducer controls all around editing of meshes, geometries and variables

export enum EGeometryDrawType {
  POLYGON = 'Polgon',
  CIRCLE = 'Circle',
  RECTANGLE = 'Rectangle',
  POINT = 'Point',
  LINE = 'Line',
}

export interface IEditState {
  previousEdits: Array<FeatureCollection<any, any>>;
  featuresForEditing: FeatureCollection<any, any>;
  loadingFeaturesForEditing: boolean;
  loadingFeaturesForEditingFailed: boolean;
  editsToApply: boolean;
  propertiesForEditing: IAppendProperties;
  editMode: EEditModeIds | 0;
  selectionMode: ESelectForEditingModeIds;
  selectionId: string;
  itemId: string;
  geometryEditItemType: EGeometryItemTypes;
  itemType: EItemType;
  geometryDrawType: EGeometryDrawType;
}

const initialState: IEditState = {
  previousEdits: new Array<FeatureCollection<any, any>>(),
  featuresForEditing: { type: 'FeatureCollection', features: [] } as FeatureCollection<any, any>,
  loadingFeaturesForEditing: false,
  loadingFeaturesForEditingFailed: false,
  editsToApply: false,
  propertiesForEditing: {} as IAppendProperties,
  editMode: 0,
  selectionMode: null,
  selectionId: null,
  itemId: null,
  geometryEditItemType: null,
  itemType: null,
  geometryDrawType: null,
};
export interface IAppendProperties {
  [key: string]: string | number;
}

export const EDIT_LAYER_ID = 'Edit_Layer';
export const EDIT_MODE = 'mmg_EditState';
export const EDIT_ID = 'mmg_CellIndex';
export const EDIT_MODE_UNCHANGED = 0;
export const EDIT_MODE_ADDED = 3;
export const EDIT_MODE_DELETED = 2;
export const EDIT_MODE_MODIFIED = 1;

/**
 * Gets a cell data id, if it exists.
 *
 * @param feature
 */
export const getFeatureCellIndex = (feature: Feature<any, any>): number => {
  if (!feature) {
    return;
  }

  if (feature.properties) {
    return feature.properties[EDIT_ID];
  }

  return;
};

const {
  update2DData,
  clearDrawnVectorLayerData,
  getCurrentlyDrawnGeojson,
  enable2DPointDrawingV2,
  disable2DPointDrawing,
  enable2DPointDrawing,
  setDrawVectorLayerGeojson,
  enable2DPolygonSelection,
  enable2DPointSelection,
  enable2DBoxSelection,
  disable2DPolygonSelection,
  disableAllDrawingTools,
  enable2DPolygonDrawingV2,
  enable2DPolylineDrawingV2,
  enable2DCircleDrawing,
  enable2DBoxDrawing,
} = MikeVisualizer;

export const modifyLineColor = tinycolor(MIKE_COLORS.GREEN_DEFAULT).toRgbString();
export const addLineColor = tinycolor(MIKE_COLORS.BRANDBLUE_DEFAULT).toRgbString();
export const deleteLineColor = tinycolor(MIKE_COLORS.PINK_DEFAULT).toRgbString();

const getStyle = (geoType: EGeometryItemTypes) => {
  const isPoint = ['Point', 'MultiPoint'].includes(geoType);
  const symbolColor = deleteLineColor;
  const symbolFill = new Fill({ color: symbolColor });
  const circleStyle = new Circle({
    fill: symbolFill,
    radius: 10,
  });
  const polyStyle = new Style({
    fill: symbolFill,
  });

  const featureStyle = (feature) => {
    const values = feature && feature.values_ ? feature.values_ : null;
    const editMode = values && values[EDIT_MODE] ? values[EDIT_MODE] : 0;
    const labelEditMode = editMode === EDIT_MODE_DELETED ? 'D' : editMode === EDIT_MODE_ADDED ? 'A' : 'M';
    const editModeLabel = new Text({
      text: labelEditMode,
      fill: new Fill({ color: MIKE_COLORS.WHITE }),
      font: `${LABELFONTSIZE} ${FONTFAMILY}`,
      justify: 'center',
    });
    if (isPoint) {
      return new Style({
        image: circleStyle,
        text: editModeLabel,
      });
    } else {
      const geometry = feature.getGeometry();
      const isPolygon = ['Polygon', 'MultiPolygon'].includes(geoType);
      if (isPolygon) {
        const labelPoint = geometry.getInteriorPoint();
        const labelStyle = new Style({
          text: editModeLabel,
          geometry: labelPoint,
        });
        return [polyStyle, labelStyle];
      } else {
        const strokeColor =
          editMode === EDIT_MODE_DELETED
            ? deleteLineColor
            : editMode === EDIT_MODE_ADDED
              ? addLineColor
              : modifyLineColor;
        return new Style({ stroke: new Stroke({ color: strokeColor, width: 4 }) });
      }
    }
  };
  return featureStyle;
};

const renderFeaturesWithAppliedChanged = (
  currentEditFeatures: Array<Feature<any>>,
  previousEdits: Array<FeatureCollection<any, any>>,
  geometryType?: EGeometryItemTypes,
) => {
  let mergedFeatures = [...currentEditFeatures];
  let ids = currentEditFeatures.map((feature: Feature<any>) => getFeatureId(feature));
  const edits = [...previousEdits.reverse()];
  edits.forEach((fc) => {
    const features = fc.features;
    features.forEach((feature: Feature<any>) => {
      const id = getFeatureId(feature);
      if (!id || !ids.includes(id)) {
        ids = [...ids, id];
        mergedFeatures = [...mergedFeatures, feature];
      }
    });
  });
  update2DData(
    { type: 'FeatureCollection', features: mergedFeatures },
    EDIT_LAYER_ID,
    null,
    null,
    1,
    getStyle(geometryType),
  );
};

/**
 * Edit Reducer.
 * Controls all around editing of meshes, geometries and variables
 * @name EditReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IEditState = initialState, action: IAction<any>) {
  switch (action.type) {
    case EMapToolActionType.SET_FEATURES_FOR_EDITING_IDS: {
      const { operationId, targetId } = action.data;
      return { ...state, selectionId: operationId, itemId: targetId };
    }
    case EMapToolActionType.VALIDATE_CHANGED_VARIABLE_POINTS: {
      const newFC = action.data;
      switch (state.editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY: {
          const currentCount = state.featuresForEditing.features.length;
          if (currentCount === 0) {
            return state;
          }
          const newCount = newFC && newFC.features ? newFC.features.length : 0;
          if (newCount !== currentCount) {
            setDrawVectorLayerGeojson(state.featuresForEditing);
          } else {
            const changed = !isEqual(state.featuresForEditing.features, newFC.features);
            if (changed) {
              let coordsChanged = false;
              const features = state.featuresForEditing.features;
              const selectedIds = features.map((feature: Feature<any>) => {
                return getFeatureId(feature);
              });
              // skip newly added features
              const cleanedFeatures = newFC.features.filter((feature: Feature<Point>) => {
                const id = getFeatureId(feature);
                return id && selectedIds.includes(id);
              });
              const updatedFeatures = cleanedFeatures.map((feature: Feature<Point>) => {
                const id = getFeatureId(feature);
                const originalFeature: Feature<Point> = features.find((origFeature: Feature<Point>) => {
                  const origId = getFeatureId(origFeature);
                  return origId === id;
                });
                const coords = feature.geometry.coordinates;
                const coordsMoved =
                  originalFeature !== undefined && !isEqual(originalFeature.geometry.coordinates, coords);
                if (coordsMoved) {
                  coordsChanged = true;
                }
                return coordsMoved
                  ? { ...feature, properties: { ...feature.properties, [EDIT_MODE]: EDIT_MODE_MODIFIED } }
                  : feature;
              });
              setDrawVectorLayerGeojson(
                updatedFeatures.length === 0
                  ? state.featuresForEditing
                  : { ...state.featuresForEditing, features: updatedFeatures },
              );

              if (coordsChanged) {
                return {
                  ...state,
                  editsToApply: changed,
                  featuresForEditing: { ...state.featuresForEditing, features: updatedFeatures },
                };
              }
            }
          }
          break;
        }
        case EEditModeIds.EDIT_MODE_ADD: {
          const newCount = newFC && newFC.features ? newFC.features.length : 0;
          let canApplyValues = newCount !== state.featuresForEditing.features.length;
          if (canApplyValues) {
            const values = Object.values(state.propertiesForEditing);
            values.forEach((val) => {
              const numericVal = getNumericValue(val);
              canApplyValues = numericVal !== null;
            });
          }
          return { ...state, editsToApply: canApplyValues };
        }
      }
      return state;
    }
    case EMapToolActionType.SET_SELECTION_MODE: {
      const mode = action.data;
      switch (mode) {
        case ESelectForEditingModeIds.SELECT_BY_POINT: {
          enable2DPointSelection(true);
          break;
        }
        case ESelectForEditingModeIds.SELECT_BY_RECTANGLE: {
          enable2DBoxSelection(true);
          break;
        }
        case ESelectForEditingModeIds.SELECT_BY_POLYGON: {
          enable2DPolygonSelection(true);
          break;
        }
        default: {
          disable2DPolygonSelection();
          break;
        }
      }
      return { ...state, selectionMode: mode };
    }
    case EMapToolActionType.SET_EDIT_MODE: {
      const { editMode, geometryType } = action.data;
      clearDrawnVectorLayerData();
      if (editMode === EEditModeIds.EDIT_MODE_ADD) {
        switch (geometryType) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT:
            enable2DPointDrawingV2({ olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE } });
            break;
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON:
            if (state.geometryDrawType === EGeometryDrawType.RECTANGLE) {
              enable2DBoxDrawing();
            } else if (state.geometryDrawType === EGeometryDrawType.CIRCLE) {
              enable2DCircleDrawing({ featureAttributes: { mmg_ExtendedGeometryType: 'circle' } });
            } else {
              enable2DPolygonDrawingV2({ vectorLayerStyle: editStyleFunction });
            }
            break;
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING:
            enable2DPolylineDrawingV2({
              vectorLayerStyle: editStyleFunction,
              olDrawOptions: { snapTolerance: 1 },
            });
            break;
          default:
            disableAllDrawingTools();
            break;
        }
      }
      return {
        ...state,
        editMode,
        featuresForEditing: initialState.featuresForEditing,
        geometryEditType: geometryType,
      };
    }
    case EMapToolActionType.ADD_DRAWN_FEATURES: {
      const geometryType = action.data;
      const newFeatureCollection = getCurrentlyDrawnGeojson(); // action.data;
      const newFeatures = newFeatureCollection.features.filter((feature: Feature<any>) => {
        const id = getFeatureCellIndex(feature);
        return id === undefined;
      });
      if (newFeatures.length) {
        const updatedFeatures =
          state.itemType === EItemType.VARIABLE
            ? transferElevationToZValue(newFeatures, state.propertiesForEditing, EDIT_MODE_ADDED)
            : addEditValue(newFeatures, EDIT_MODE_ADDED);
        clearDrawnVectorLayerData();
        renderFeaturesWithAppliedChanged(updatedFeatures, state.previousEdits, geometryType);
        return {
          ...state,
          previousEdits: [
            ...state.previousEdits,
            { type: 'FeatureCollection', features: updatedFeatures } as FeatureCollection<any, any>,
          ],
          editsToApply: false,
          featuresForEditing: initialState.featuresForEditing,
        };
      }
      return state;
    }

    case EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_CREATING: {
      return { ...state, propertiesForEditing: DEFAULT_VARIABLE_PROPERTIES as IAppendProperties };
    }
    case EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_EDITING: {
      const dataArrays = action.data;
      const cellDdataArrays = dataArrays.filter((da) => da.type.toLowerCase() === CELLDATA);
      const ids: Array<string> = cellDdataArrays.map(({ id }) => id.toString().toLowerCase());
      const properties: IAppendProperties = {};
      ids.forEach((id) => {
        if (!IGNORE_VARIABLE_PROPERTIES.includes(id) && !id.startsWith(MMG_)) {
          properties[id] = 0;
        }
      });
      return { ...state, propertiesForEditing: properties as IAppendProperties };
    }
    case EMapToolActionType.STORE_FEATURES_FOR_EDITING: {
      const geometryType = action.data;
      const changedProperty = state.editMode === EEditModeIds.EDIT_MODE_DELETE ? EDIT_MODE_DELETED : EDIT_MODE_MODIFIED;
      const editFeatures = [...state.featuresForEditing.features];
      const newFeatures =
        state.editMode === EEditModeIds.EDIT_MODE_MODIFY
          ? state.featuresForEditing.features.filter((feature: Feature<any>) => {
              return (
                feature.properties && feature.properties[EDIT_MODE] && feature.properties[EDIT_MODE] === changedProperty
              );
            })
          : editFeatures.map((feature: Feature<any>) => {
              const f = { ...feature, properties: { ...feature.properties, [EDIT_MODE]: changedProperty } };
              return f;
            });

      const updatedFeatures =
        state.editMode === EEditModeIds.EDIT_MODE_ATTRIBUTION && state.itemType === EItemType.VARIABLE
          ? transferElevationToZValue(newFeatures, state.propertiesForEditing, EDIT_MODE_MODIFIED)
          : newFeatures;
      clearDrawnVectorLayerData();
      renderFeaturesWithAppliedChanged(updatedFeatures, state.previousEdits, geometryType);
      enable2DBoxSelection();
      return {
        ...state,
        previousEdits: [
          ...state.previousEdits,
          { type: 'FeatureCollection', features: updatedFeatures } as FeatureCollection<any, any>,
        ],
        editsToApply: false,
        featuresForEditing: initialState.featuresForEditing,
        selectionMode: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
      };
    }
    case EMapToolActionType.UNDO_FEATURES_FOR_EDITING: {
      const geometryType = action.data;
      const previousEditCount = state.previousEdits.length;
      if (previousEditCount === 0) {
        return state;
      }
      const previouslyEditedFeatureCollections = state.previousEdits.slice(0, -1);
      clearDrawnVectorLayerData();
      renderFeaturesWithAppliedChanged([], previouslyEditedFeatureCollections, geometryType);
      return {
        ...state,
        featuresForEditing: initialState.featuresForEditing,
        previousEdits: previouslyEditedFeatureCollections,
        editsToApply: false,
      };
    }
    case EMapToolActionType.CLEAR_SELECTION_FOR_EDITING: {
      clearDrawnVectorLayerData();
      const properties: IAppendProperties = {};
      const ids: Array<string> = Object.keys(state.propertiesForEditing);
      ids.forEach((id) => {
        properties[id] = 0;
      });
      return {
        ...state,
        editsToApply: false,
        propertiesForEditing: properties as IAppendProperties,
        featuresForEditing: initialState.featuresForEditing,
      };
    }
    case EMapToolActionType.CLEAR_FEATURES_FOR_EDITING: {
      clearDrawnVectorLayerData();
      return {
        ...state,
        editsToApply: false,
        previousEdits: new Array<FeatureCollection<any, any>>(),
        loadingFeaturesForEditing: false,
        propertiesForEditing: {} as IAppendProperties,
        featuresForEditing: { type: 'FeatureCollection', features: [] } as FeatureCollection<any, any>,
      };
    }
    case EMapToolActionType.UPDATE_FEATURES_FOR_EDITING_VALUE: {
      const { key, value } = action.data;

      const numericValue = getNumericValue(value);
      let canApplyValues = numericValue !== null;
      if (canApplyValues) {
        const keys = Object.keys(state.propertiesForEditing);
        keys.forEach((k: string) => {
          if (k !== key) {
            const numericVal = getNumericValue(state.propertiesForEditing[k]);
            canApplyValues = numericVal !== null;
          }
        });
      }

      if (state.editMode === EEditModeIds.EDIT_MODE_ADD) {
        const newFeatureCollection = getCurrentlyDrawnGeojson();
        return {
          ...state,
          editsToApply: canApplyValues && newFeatureCollection.features.length > 0,
          propertiesForEditing: { ...state.propertiesForEditing, [key]: numericValue } as IAppendProperties,
        };
      } else {
        return {
          ...state,
          editsToApply: canApplyValues,
          propertiesForEditing: { ...state.propertiesForEditing, [key]: numericValue } as IAppendProperties,
        };
      }
    }
    case EMapToolActionType.LOADING_FEATURES_FOR_EDITING: {
      return { ...state, loadingFeaturesForEditing: action.data, editsToApply: false };
    }
    case EMapToolActionType.SET_GEOPROCESSING_EDITS_TO_APPLY: {
      return { ...state, editsToApply: true, featuresForEditing: action.data };
    }
    case EMapToolActionType.LOADING_FEATURES_FOR_EDITING_FAILED: {
      return { ...state, loadingFeaturesForEditing: false, loadingFeaturesForEditingFailed: true };
    }
    case EMapToolActionType.SET_DRAW_TYPE: {
      const drawType = action.data;
      switch (drawType) {
        case EGeometryDrawType.RECTANGLE: {
          enable2DBoxDrawing();
          break;
        }
        case EGeometryDrawType.CIRCLE: {
          enable2DCircleDrawing({ featureAttributes: { mmg_ExtendedGeometryType: 'circle' } });
          break;
        }
        case EGeometryDrawType.POLYGON: {
          enable2DPolygonDrawingV2({ vectorLayerStyle: editStyleFunction });
          break;
        }
        case EGeometryDrawType.LINE: {
          enable2DPolylineDrawingV2({
            vectorLayerStyle: editStyleFunction,
            olDrawOptions: { snapTolerance: 1 },
          });
          break;
        }
        default: {
          enable2DPointDrawingV2({ olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE } });
          break;
        }
      }
      return { ...state, geometryDrawType: action.data };
    }
    case EMapToolActionType.GEOMETRY_EDIT: {
      const editItemType = action.data.geometryEditItemType;
      let drawType;
      switch (editItemType) {
        case EGeometryItemTypes.LINE_STRING:
        case EGeometryItemTypes.MULTI_LINE_STRING:
          drawType = EGeometryDrawType.LINE;
          break;
        case EGeometryItemTypes.POLYGON:
        case EGeometryItemTypes.MULTI_POLYGON:
          drawType = EGeometryDrawType.POLYGON;
          break;
        default: {
          drawType = EGeometryDrawType.POINT;
          break;
        }
      }
      return { ...state, geometryEditItemType: editItemType, itemType: EItemType.GEOMETRY, geometryDrawType: drawType };
    }
    case EMapToolActionType.GEOMETRY_CREATE: {
      return {
        ...state,
        itemType: EItemType.GEOMETRY,
        geometryDrawType: action.data.drawType,
        geometryEditItemType: action.data.geometryType,
      };
    }
    case EMapToolActionType.VARIABLE_CREATE: {
      return { ...state, itemType: EItemType.VARIABLE };
    }
    case EMapToolActionType.VARIABLE_EDIT: {
      return { ...state, geometryEditItemType: null, itemType: EItemType.VARIABLE };
    }
    case EWorkspaceMeshActionType.EDIT_NODES: {
      return { ...state, geometryEditItemType: null, itemType: EItemType.MESH };
    }
    case EMapToolActionType.SET_FEATURES_FOR_EDITING: {
      const featureCollection = action.data.featureCollection;
      if (action.data.operationId !== state.selectionId) {
        return state;
      }
      const selectedCount = featureCollection && featureCollection.features ? featureCollection.features.length : 0;

      const featuresWithId =
        selectedCount > 0
          ? featureCollection.features.map((feature: Feature<any>) => {
              const id = getFeatureId(feature);
              if (!id) {
                const newId = uuid();
                return {
                  ...feature,
                  id: newId,
                  properties: { ...feature.properties, id: newId },
                };
              } else {
                return feature;
              }
            })
          : featureCollection.features;
      const featureCollectionWithId = { ...featureCollection, features: featuresWithId };

      const getValue = (key: string, features: Array<Feature<any>>) => {
        const values = features.map((feature: Feature<any>) => {
          const properties = feature.properties;
          return properties[key];
        });
        const numericValues = values.filter((value: any) => value !== undefined && isNumeric(value.toString()));
        const uniqValues = uniq(numericValues);
        if (uniqValues.length === 1) {
          return uniqValues[0];
        } else if (uniqValues.length > 1) {
          const sortedValues = uniqValues.sort();
          return 'range: ' + sortedValues[0] + ' - ' + sortedValues[sortedValues.length - 1];
        } else {
          return undefined;
        }
      };
      if (state.itemType === EItemType.VARIABLE) {
        const properties: IAppendProperties = {};
        if (selectedCount > 0) {
          const ids: Array<string> = Object.keys(state.propertiesForEditing);
          ids.forEach((id) => {
            properties[id] = getValue(id, featureCollectionWithId.features);
          });
        }

        switch (state.editMode) {
          case EEditModeIds.EDIT_MODE_MODIFY:
            enable2DPointDrawing(true, false);
            break;
          default:
            disable2DPointDrawing();
            break;
        }
        setDrawVectorLayerGeojson(featureCollectionWithId);
        return {
          ...state,
          featuresForEditing: featureCollectionWithId,
          editsToApply: false,
          propertiesForEditing: properties,
        };
      } else {
        if (
          state.itemType === EItemType.GEOMETRY &&
          (state.editMode === EEditModeIds.EDIT_MODE_MODIFY || state.editMode === EEditModeIds.EDIT_MODE_ADD)
        ) {
          switch (state.geometryEditItemType) {
            case EGeometryItemTypes.POLYGON:
            case EGeometryItemTypes.MULTI_POLYGON:
              enable2DPolygonDrawingV2({ vectorLayerStyle: editStyleFunction });
              break;
            case EGeometryItemTypes.LINE_STRING:
            case EGeometryItemTypes.MULTI_LINE_STRING:
              enable2DPolylineDrawingV2({
                vectorLayerStyle: editStyleFunction,
                olDrawOptions: { snapTolerance: 1 },
              });
              break;
            case EGeometryItemTypes.POINT:
            case EGeometryItemTypes.MULTI_POINT:
              enable2DPointDrawingV2({ olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE } });
              break;
            default:
              disableAllDrawingTools();
              break;
          }
        }
        setDrawVectorLayerGeojson(featureCollectionWithId);
        return {
          ...state,
          featuresForEditing: featureCollectionWithId,
          editsToApply: false,
        };
      }
    }
    case EWorkspaceActionType.CLOSE: {
      return { ...state, ...initialState };
    }
    case EMapToolActionType.VARIABLES_UNLOAD: {
      return { ...state, ...initialState };
    }

    default:
      return state;
  }
}
