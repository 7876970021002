/**
 * Exposes Platform REST endpoints.
 *
 * @module PlatformProxy
 * @version 2.0.0
 * @requires Proxy
 */
//import { endpoints, paths } from 'proxies/config';
import { endpoints } from './config';
import Proxy from './Proxy';

let apiUrl = '';

export default function init(url: string) {
  apiUrl = url;
}

/**
 * Gets a list of all applications the user has access to.
 *
 */
const getApplications = () => {
  const headerConfig = {
    headers: {
      'Content-Type': 'application/json',
      'api-version': 3,
      'dhi-service-id': 'iam',
    },
  };
  return Proxy.http.get(apiUrl + '/api/admin/feature/tenant/list', headerConfig);
};

const getSASToken = (projectId: string) => {
  const headerConfig = {
    headers: {
      'api-version': 1,
    },
  };
  return Proxy.http.get(apiUrl + `/api/services/sastoken?projectId=${projectId}`, headerConfig);
};

const getUserTenants = () => {
  const headerConfig = {
    headers: {
      'api-version': 3,
      'dhi-service-id': 'iam',
    },
  };
  return Proxy.http.get(apiUrl + `/api/iam/tenant/my`, headerConfig);
};

export const getTenantCapabilites = (projectId) =>
  Proxy.http.get(apiUrl + `/api/metadata/project/${projectId}/capabilities`, {
    headers: {
      'api-version': 3,
    },
  });

export const PlatformProxy = {
  init,
  getApplications,
  getSASToken,
  getUserTenants,
  getTenantCapabilites,
};
